import React, { useEffect, useState } from "react";
import {CssBaseline, ThemeProvider} from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import theme from './theme/application';
import IyoAuth from "./auth/Auth.jsx";
import Routes from "./components/routes/Routes.jsx";
import HubListener from './components/auth/HubListener.jsx';
import NavigationLayout from './components/navigation/NavigationLayout';
import NavigationTopBar from './components/navigation/topbar/NavigationTopBar';
import NavigationSideBar from './components/navigation/sidebar/NavigationSideBar';
import NavigationBottomBar from './components/navigation/bottombar/NavigationBottomBar';
import { ReactQueryDevtools } from 'react-query/devtools';

const businessList = [
  {
    id: 42,
    name: 'Cardi Bs Cows'
  },
  {
    id: 32,
    name: 'Nathans Pitchfork Emporium'
  }
];

const NavTopBarNAble = () => {

  return (
    <NavigationTopBar businessList={businessList} />
  );
}

function App() {
  const [sessionChange, setSessionChange] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    IyoAuth.isLoggedIn().then(bool => {
      setLoggedIn(bool);
    });
    setSessionChange(false);
  }, [sessionChange, setSessionChange]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <HubListener setSessionUpdate={setSessionChange}/>
        <NavigationLayout isLoggedIn={isLoggedIn}
                          topbar={NavTopBarNAble}
                          sidebar={NavigationSideBar}
                          bottombar={NavigationBottomBar} >
          <Routes/>
        </NavigationLayout>
      </Router>
      {
        process.env.DOMAIN === undefined || process.env.DOMAIN === '' ?
          <ReactQueryDevtools initialIsOpen={false} /> :
          null
      }
    </ThemeProvider>
  );
}

export default App;