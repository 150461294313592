import {useQuery} from 'react-query';
import ConsumerService from "../../../apis/ConsumerService";
import OwnersService from "../../../apis/OwnersService";
import ProviderService from "../../../apis/ProviderService";
import PageHelpers from '../helpers';
import DialogHelpers from './helpers';

const useConsumerQuery = (method, setState) => {
  switch (method) {
    case 'GET':
      return useQuery('getConsumers', async () => {
        return await ConsumerService.getAll();
      },
      {
        onError: (error) => {
          PageHelpers.produceError(error, setState);
        },
        staleTime: Infinity,
      }
    );
  }
};

const useInitialValuesQuery = (state, setState, getProperties) => {
  return useQuery(['getInitialValues', getProperties, state.providerId], () => {
      const initialFields = getProperties.data || null;
      return {
        name: state.rowEditing?.name ? state.rowEditing.name : state.name,
        description: state.rowEditing?.description ? state.rowEditing.description : state.description,
        ...DialogHelpers.prettifyProperties(initialFields)
      };
    },
  {
      onError: (error) => {
        PageHelpers.produceError(error, setState);
    },
      staleTime: Infinity,
    }
  );
};

const useOwnerQuery = (method, setState) => {
  switch (method) {
    case 'GET':
      return useQuery('getOwners', async () => {
          return await OwnersService.getAll();
        },
        {
          onError: (error) => {
            PageHelpers.produceError(error, setState);
          },
          staleTime: Infinity
        }
      );
  }
};

const useProviderQuery = (method, setState) => {
  return useQuery('getProviders', async () => {
      return await ProviderService.getAll();
    },
    {
      onError: (error) => {
        PageHelpers.produceError(error, setState);
      },
      staleTime: Infinity
    }
  );
};

const usePropertiesQuery = (state, setState, getProviders) => {
  return useQuery(['getProperties', getProviders, state.providerId, state.rowEditing], async () => {
      if (!getProviders.data || !state.providerId) return [];

      // Retrieve the full list of properties from the selected provider's schema.
      // These define the text fields that will be added to the form.
      const providerSchema = JSON.parse(getProviders.data
        .filter(provider => provider.id === state.providerId)[0].schema);

      const schemaProperties = Object.keys(providerSchema.properties);

      // Retrieve the actual properties from the selected provider.
      // These will be the values that initially fill in the text fields created above.
      const defaultProperties = JSON.parse(getProviders.data
        .filter(provider => provider.id === state.providerId)[0].properties);

      // Retrieve the specific instance/override of the properties from the data stream itself
      let dataStreamProperties = {};
      if (state.editMode === 'edit') dataStreamProperties = JSON.parse(state.rowEditing?.data_stream_provider_properties);

      // Return a merged form of the above lists.
      // Fields are defined by the schema. The initial values are the provider's properties. And these are overridden by
      // the data stream's properties.
      return schemaProperties.map(prop => {
        let property = {
          name: prop,
          value: '',
          required: providerSchema.required.includes(prop)
        };
        if (defaultProperties[prop]) {
          property = {
            name: prop,
            value: defaultProperties[prop],
            required: providerSchema.required.includes(prop)
          };
        }
        if (state.editMode === 'create') return property;
        if (dataStreamProperties[prop] && state.rowEditing.provider_id === state.providerId) {
          if (dataStreamProperties[prop] !== '') {
            property = {
              name: prop,
              value: dataStreamProperties[prop],
              required: providerSchema.required.includes(prop)
            };
          }
        }
        return property;
      });
    },
    {
      onError: (error) => {
        PageHelpers.produceError(error, setState);
    },
      staleTime: Infinity
    }
  );
};

export default {useConsumerQuery, useInitialValuesQuery, useOwnerQuery, useProviderQuery, usePropertiesQuery};