import * as Yup from "yup";

const generateValidationSchema = (fields) => {
  let schemaObject = {
    name: Yup.string().required('Required')
  };

  if (!fields) return schemaObject;

  for (let field of fields) {
    if (field.required) {
      schemaObject[field.name] = Yup.string().required('Required');
    }
  }
  return schemaObject;
};

const prettifyProperties = (properties) => {
  if (!properties) return null;

  let newFields = {};
  for (let field of properties) {
    Object.defineProperty(newFields, field.name, {value: field.value ? field.value : '', enumerable: true});
  }
  return newFields;
};

export default {generateValidationSchema, prettifyProperties};