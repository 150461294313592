import React from 'react';
import ConditionalRoute from "./ConditionalRoute";
import Auth from "../../auth/Auth";

export default function PrivateRoute({children, render, ...att }) {
  return (
    <ConditionalRoute conditionCallback={Auth.isLoggedIn}
                      render={render || (_ => children)}
                      failRedirect='/login'
                      loadMessage='Checking for Session...'
                      props={att}
    />
  );
};