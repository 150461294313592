import React from 'react';
import {Box, Container, Toolbar, useTheme} from "@mui/material";

const defaultContainerSx = {};

const NavigationLayout = ({isLoggedIn, topbar, sidebar, bottombar, children, containerSx}) => {

  const theme = useTheme();

  const TopBar = topbar || (() => {return (<></>)})
  const SideBar = sidebar || (() => {return (<></>)});
  const BottomBar = bottombar || (() => {return (<></>)});

  return (
    <>
      {isLoggedIn && (<>
        <TopBar />
      </>)}
      {isLoggedIn && (<SideBar />)}
      <Toolbar />
      <Box component="main"
           sx={{
             width: {
               xs: '100%',
               sm: '100%',
               md: `calc(100% - ${theme.components.MuiDrawer?.styleOverrides?.root?.width || '0px'})`
             },
             float: 'right',
             flexGrow: 1
           }}>
        <Container sx={{...defaultContainerSx, ...containerSx}} maxWidth="100%">
          <Box sx={{pt: 2.4}}>
            {children}
          </Box>
        </Container>
      </Box>
      {isLoggedIn && (<BottomBar />)}
    </>
  );
};

export default NavigationLayout;