import {Helmet} from "react-helmet";
import {Alert, AlertTitle, Box, Button, Container, TextField, Typography} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import React, {useState} from "react";
import {Auth} from 'aws-amplify';
import {useSelector} from "react-redux";
import {attemptedLoginUser, attemptedLoginPass} from "../../userContextSlice";
import {useHistory} from "react-router-dom";

const ChangePassword = () => {

  const history = useHistory();
  const [error, setError] = useState(null);

  const username = useSelector(attemptedLoginUser);
  const password = useSelector(attemptedLoginPass);

  const handleLoginSubmit = async (values, actions) => {
    Auth.signIn(username, password)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(user, values.newPassword)
            .then(_ => {
              history.push('/');
            })
            .catch((error) => {
              console.error(error);
              setError(error);
              actions.setSubmitting(false);
            });
        }
        else {
          history.push('/');
        }
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Change Password | nGEST</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Alert severity="info" sx={{my: 2}}>
            <AlertTitle>Hi there</AlertTitle>
            You must change your password to continue.
          </Alert>
          {error?.message ? (
            <Alert severity="error" sx={{my: 2}}>
              <AlertTitle>Login Failed</AlertTitle>
              Invalid user/credentials
            </Alert>
          ) : (<></>)}
          <Formik
            initialValues={{
              username: username,
              password: password,
              newPassword: ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().max(255).required('Username is required'),
              password: Yup.string().max(255).required('Password is required'),
              newPassword: Yup.string().max(255).required('New password is required')
            })}
            onSubmit={(values, actions) => handleLoginSubmit(values, actions)}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="username"
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  fullWidth
                  helperText={touched.newPassword && errors.newPassword}
                  label="New Password"
                  margin="normal"
                  name="newPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.newPassword}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ChangePassword;