import React, { useEffect } from 'react';
import { Hub } from 'aws-amplify';
import {useHistory} from "react-router-dom";

export default function HubListener({setSessionUpdate}) {

  const history = useHistory();

  useEffect(() => {
    Hub.listen('auth', ({payload: {event, data}}) => {
      switch (event) {
        case 'signIn':
          setSessionUpdate(true);
          break;
        case 'signOut':
          setSessionUpdate(true);
          break;
        case 'signIn_failure':
          if (data.code === "UserNotConfirmedException") {
            history.push('/confirm');
          }
          break;
        case 'signUp':
        case 'signUp_failure':
          break;
        default:
          console.info('Hub::UnhandledCase', event);
      }
    });
  }, []);
  return (<></>)
}
