import React from 'react';
import IyoAuth from "../../auth/Auth";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import EditIcon from "@mui/icons-material/Edit";
import {IconButton, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Handlers from './handlers';

const deleteConsumers = async (dataStreamId, consumerList, deleteDataStreamConsumer) => {
  for (const id of consumerList) {
    deleteDataStreamConsumer.mutate({dataStreamId: dataStreamId, consumerId: id});
  }
};

const initializeColumns = (columns, queryClient, setState, getDataStreamConsumers) => {
  columns.push(
    {
      field: 'provider_schema',
      headerName: 'Provider Schema',
      description: 'Provider Schema',
      minWidth: 225,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View Schema">
              <IconButton color='primary' onClick={(event) => Handlers.handleDialogOpen(event,
                'provider_schema', params.row, setState)}>
                <InfoIcon/>
              </IconButton>
            </Tooltip>
            &nbsp;
            <Tooltip title="View Properties">
              <IconButton color='primary' onClick={(event) => Handlers.handleDialogOpen(event,
                'provider_properties', params.row, setState)}>
                <BuildCircleIcon/>
              </IconButton>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'data_stream_provider_properties',
      headerName: 'Datastream Properties',
      description: 'Datastream Properties',
      minWidth: 250,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View Properties">
              <IconButton color='primary' onClick={(event) => Handlers.handleDialogOpen(event,
                'data_stream_provider_properties', params.row, setState)}>
                <BuildCircleIcon/>
              </IconButton>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      description: 'Actions',
      minWidth: 175,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton color='primary' onClick={(event) => Handlers.handleEdit(event, params.row, queryClient,
                setState)}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
          </>
        );
      }
    }
  );
};

const produceError = (error, setState) => {
  const message = `${error.message}: ${JSON.stringify(error.response.data)}`;

  setState.setErrorOpen(false);
  setState.setErrDisplayText(message);
  setState.setErrorOpen(true);

  console.error(message);
};

const submitConsumers = async (dataStreamId, consumerList, postDataStreamConsumer) => {
  for (const id of consumerList) {
    await postDataStreamConsumer.mutateAsync({
      data_stream_id: dataStreamId,
      consumer_id: id,
      created_by: await IyoAuth.getUser(),
      updated_by: await IyoAuth.getUser()
    });
  }
};

const updateDataStream = async (values, state, putDataStream) => {
  let body = {
    provider_properties: {}
  };

  for (let field in values) {
    if (values[field] !== state.rowEditing[field]) {
      if (state.dataStreamFields.includes[field]) {
        body[field] = values[field];
      } else {
        body.provider_properties[field] = values[field];
      }
    }
  }

  body['provider_id'] = state.providerId;
  body['owner_id'] = state.ownerId;

  body['updated_by'] = await IyoAuth.getUser();
  body['id'] = state.rowEditing.id;

  putDataStream.mutate(body);
};

const updateDataStreamConsumers = async (state, postDataStreamConsumer, deleteDataStreamConsumer) => {
  let joined = state.originalConsumers.concat(state.consumerId);

  let toAdd = [];
  let toRemove = [];

  for (let i = 0; i < joined.length; i++) {
    if (!state.originalConsumers.includes(joined[i])) {
      toAdd.push(joined[i]);
    }
    if (!state.consumerId.includes(joined[i])) {
      toRemove.push(joined[i]);
    }
  }

  await submitConsumers(state.dataStreamId, toAdd, postDataStreamConsumer);
  await deleteConsumers(state.dataStreamId, toRemove, deleteDataStreamConsumer);
};

export default {submitConsumers, deleteConsumers, initializeColumns, produceError, updateDataStream,
  updateDataStreamConsumers};