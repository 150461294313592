import React from 'react';
import PrivateRoute from '../privateRoute/PrivateRoute.jsx';
import Login from '../../pages/login/Login.jsx';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import SessionInfo from "../../pages/info/SessionInfo";
import {Box} from "@mui/material";
import PublicOnlyRoute from "../privateRoute/PublicOnlyRoute";
import Owners from "../../pages/owners/Owners";
import Providers from "../../pages/providers/Providers";
import Consumers from "../../pages/consumers/Consumers";
import DataStreams from "../data-streams/DataStreams";
import ProviderTypes from "../../pages/providers/ProviderTypes";
import ConsumerTypes from "../../pages/consumers/ConsumerTypes";
import ChangePassword from "../../pages/login/ChangePassword";

function Routes() {
  return (
    <div>
      <Switch>
        <Route path="/info" render={props => <SessionInfo {...props} />} />
        <Route path="/" exact render={_ => <Redirect to="/home"/>} />
        <PublicOnlyRoute path="/login" render={props => <Login {...props} />} />
        <Route path="/changepassword" render={props => <ChangePassword {...props} />} />
        <PrivateRoute path="/home" render={props => <Box {...props} />} />
        <PrivateRoute path="/owners" render={props => <Owners {...props} />} />
        <PrivateRoute path="/providers" render={props => <Providers {...props} />} />
        <PrivateRoute path="/consumers" render={props => <Consumers {...props} />} />
        <PrivateRoute path="/data-streams" render={props => <DataStreams {...props} />} />
        <PrivateRoute path="/providerTypes" render={props => <ProviderTypes {...props} />} />
        <PrivateRoute path="/consumerTypes" render={props => <ConsumerTypes {...props} />} />
      </Switch>
    </div>
  )

}

export default Routes;
