import axios from 'axios';
import {fetchConfig} from '../../util/util';

const HOST_URL = fetchConfig('hostUrl')

const getOneById = async (dataStreamId) => {
  return (await axios.get(`${HOST_URL}data-stream/${dataStreamId}`)).data;
};

const getAll = async () => {
  return (await axios.get(`${HOST_URL}data-stream`)).data;
};

const post = async (data) => {
  return (await axios.post(`${HOST_URL}data-stream`, data)).data;
};

const put = async (dataStreamId, data) => {
  return (await axios.put(`${HOST_URL}data-stream/${dataStreamId}`, data)).data;
};

export default {getOneById, getAll, post, put};