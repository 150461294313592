import React from 'react';
import {Box} from "@mui/material";

const dropDownStyle = {
	mx: '1.5rem',
	my: '1.5rem',
	borderRadius: '30px',
	borderColor: 'primary',
	backgroundColor: 'white',
	height: '2.5rem',
	fontSize: '0.8rem',
};

const NavigationTopBarContent = ({children, dropDownSx }) => {
	return (
		<Box sx={{flexGrow: 4, display: 'flex', flexDirection: 'row'}}>
			<Box sx={{flexGrow: 4, display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'right'}}>
				{children}
			</Box>
		</Box>
	);
};

export default NavigationTopBarContent;