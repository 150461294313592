import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Formik} from "formik";
import Grid from '@mui/material/Grid';
import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import IyoFormikDropDown from '../common/IyoFormikDropDown';
import * as Yup from "yup";
import {useQuery} from "react-query";
import ConsumerTypeService from "../../apis/ConsumerTypeService";

function ConsumerEditDialog(props) {

    // ####################[ Query Definitions ]####################
    const getConsumerTypes = useQuery('getConsumerTypes', async () => {
            return await ConsumerTypeService.getAll();
        },
        {
            onError: (error) => {
                props.handleError(error);
            },
            retry: false,
            staleTime: Infinity,
        }
    );

    // ####################[ Event Handlers ]####################
    const handleClose = () => {
        props.setOpen(false);
    };

    const handleConsumerTypeSelect = (selection, values, resetForm) => {
        props.setConsumerTypeId(selection.value);

        const newFields = getConsumerTypeProperties(selection.value);

        resetForm({
            values: {
                name: values.name,
                description: values.description,
                consumer_type_id: selection.value,
                ...prettifyProperties(newFields)
            }
        });
    };

    const validate = async (values) => {
        const fields = getConsumerTypeProperties(props.consumerTypeId);
        const validationObject = generateValidationSchema(fields);

        let errors = {};

        for (let field in validationObject) {
            try {
                await validationObject[field].validate(values[field]);
            } catch (err) {
                errors[field] = err.errors[0];
            }
            // TODO will probably have to turn an array of errors from Yup into a single string with commas or something (Formik doesn't tell you how to do multiple errors)
        }

        return errors;
    };

    // ####################[ Helper Functions ]####################
    const generateValidationSchema = (fields) => {
        let schemaObject = {name: Yup.string().required('Required')};  // A starting point; name always required

        if (!fields) return schemaObject;
        for (let field of fields) {
            if (field.required) {
                schemaObject[field.name] = Yup.string().required('Required');
            }
        }
        return schemaObject;
    };

    const getConsumerTypeProperties = (consumerTypeId) => {
        if (!getConsumerTypes.data || !consumerTypeId) return [];

        // Retrieve the full list of properties from the selected (via the dropdown) provider type's schema.
        // These define the text fields that will be added to the form.
        const consumerTypeSchema = JSON.parse(getConsumerTypes.data
            .filter(consumerType => consumerType.id === consumerTypeId)[0].schema);

        const allProperties = Object.keys(consumerTypeSchema.properties);

        // Retrieve the actual properties from the provider being edited.
        // These will be the values that fill in the text fields created above.
        const currentProperties = props.rowEditing?.properties ? JSON.parse(props.rowEditing.properties) : {};

        // Return a merged form of the above two.
        // Values are only filled in if the provider type of the provider being edited and the selected (dropdown) provider type are the same.
        return allProperties.map(prop => ({
            name: prop,
            value: props.rowEditing?.consumer_type_id === consumerTypeId ? currentProperties[prop] : null,
            required: consumerTypeSchema.required.includes(prop)
        }));
    };

    const prettifyProperties = (properties) => {
        if (!properties) return null;

        let newFields = {};
        for (let field of properties) {
            Object.defineProperty(newFields, field.name, {value: field.value ? field.value : '', enumerable: true});
        }
        return newFields;
    };

    const setInitialValues = () => {

        const initialFields = getConsumerTypeProperties(props.rowEditing?.consumer_type_id);

        return {
            name: props.rowEditing?.name ? props.rowEditing.name : '',
            description: props.rowEditing?.description ? props.rowEditing.description : '',
            consumer_type_id: props.rowEditing?.consumer_type_id ? props.rowEditing.consumer_type_id : '',
            ...prettifyProperties(initialFields)
        };
    };

    // ####################[ The Component ]####################
    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            maxWidth='lg'
            fullWidth={true}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>
                {
                    props.mode === 'edit' ? `Edit Consumer ${props.rowEditing.id}` :
                        props.mode === 'create' ? 'Create Consumer' : ''
                }
            </DialogTitle>
            <Formik
                initialValues={setInitialValues()}
                validate={values => validate(values)}
                onSubmit={props.mode === 'edit' ? (values) => props.handleEditSubmit(values) :
                    props.mode === 'create' ? (values) => props.handleCreateSubmit(values) :
                        () => {
                        }}
            >
                {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      resetForm,
                      touched,
                      values
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div style={{margin: '2%'}}>
                            <Grid container spacing={2}>
                                {/* ##########################[ Row 1 ]############################## */}
                                <Grid item xs={4}>
                                    <TextField
                                        name='name'
                                        label='Name'
                                        value={values.name}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                        fullWidth
                                        margin='normal'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item xs={8}/>
                                {/* ##########################[ Row 2 ]############################## */}
                                <Grid item xs={6}>
                                    <TextField
                                        name='description'
                                        label='Description'
                                        value={values.description}
                                        error={Boolean(touched.description && errors.description)}
                                        helperText={touched.description && errors.description}
                                        fullWidth
                                        margin='normal'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item xs={6}/>
                                {/* ##########################[ Row 3 ]############################## */}
                                <Grid item xs={6}>
                                    <IyoFormikDropDown
                                        fieldName='consumer_type_id'
                                        dataList={getConsumerTypes.data ? getConsumerTypes.data : []}
                                        value={props.consumerTypeId ? props.consumerTypeId : ''}
                                        errors={errors}
                                        touched={touched}
                                        handleChange={(selection) => handleConsumerTypeSelect(selection, values, resetForm)}
                                    />
                                </Grid>
                                <Grid item xs={6}/>
                                {/* ##########################[ Row 4 thru x ]############################## */}
                                {
                                    getConsumerTypeProperties(props.consumerTypeId).map(field => (
                                        <>
                                            <Grid item xs={6}>
                                                <TextField
                                                    name={field.name}
                                                    label={field.name}
                                                    value={values[field.name]}
                                                    error={Boolean(touched[field.name] && errors[field.name])}
                                                    helperText={touched[field.name] && errors[field.name]}
                                                    fullWidth
                                                    margin='normal'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                            <Grid item xs={6}/>
                                        </>
                                    ))
                                }
                                {/*##########################[ Row (x+1) ]##############################*/}
                                <Grid item xs={12}>
                                    <div>
                                        <Button
                                            color='primary'
                                            disabled={isSubmitting}
                                            onClick={handleClose}
                                            size='large'
                                            type='button'
                                            variant='outlined'
                                        >
                                            Cancel
                                        </Button>
                                        &nbsp;
                                        <Button
                                            color='primary'
                                            disabled={isSubmitting}
                                            size='large'
                                            type='submit'
                                            variant='contained'
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
}

export default ConsumerEditDialog;