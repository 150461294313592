import React from 'react';
import { createTheme } from '@mui/material/styles';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

const drawerWidthRem = 7;

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
          boxShadow: 'none',
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default
        }
      }
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        disableRipple: true,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          height: 'fit-content',
          maxHeight: '90vh',
          padding: '1rem',
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        variant: 'temporary',
        ModalProps: {
          keepMounted: true,
        },
        PaperProps: {
          elevation: 8,
        },
      },
      styleOverrides: {
        root: {
          width: `${drawerWidthRem}rem`,
          flexShrink: {
            sm: 0,
          },
          display: {
            xs: 'none',
            md: 'block'
          },
        },
        paper: {
          boxSizing: 'border-box',
          width: `${drawerWidthRem}rem`,
          backgroundColor: palette.background.default,
        },
      },
    },
    MuiIcon: {
      defaultProps: {
        color: 'secondary',
      },
      variants: [
        {
          props: {
            variant: 'notifyDisabled',
          },
          style: {
            color: '#744fbf',
          },
        },
        {
          props: {
            variant: 'clickable',
          },
          style: {
            cursor: 'pointer',
          },
        },
      ],
    },
    MuiPaper: {
      defaultProps: {
        position: 'static',
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem',
        },
      },
      variants: [
        {
          props: {
            variant: 'enabled',
          },
        }, {
          props: {
            variant: 'disabled',
          },
          style: {
            color: "#b2b29b",
          },
        },
      ],
    },
  },
  shadows,
});

export default theme;
