import React from 'react';
import {useEffect, useState} from "react";
import IyoAuth from "../../auth/Auth";
import {Card, Typography} from "@mui/material";

const SessionInfo = () => {
    const [user, setUser] = useState();
    const [authIdToken, setAuthIdToken] = useState(null);
    const [isLoggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        IyoAuth.getIdToken().then(token => {
            console.log('ID Token=', token);
            setAuthIdToken(JSON.stringify(token));
        });
        IyoAuth.getUser().then(myUser => {
            setUser(myUser);
        });
        IyoAuth.isLoggedIn().then(bool => {
            setLoggedIn(bool);
        });
    }, []);

    return (
        <Card>
            <Typography variant="h1">nGEST</Typography>
            <br /><br /><br /><br />
            <Typography variant="h4">Auth:idToken{authIdToken}</Typography>
            <Typography variant="h4">isLoggedIn:{isLoggedIn}</Typography>
            <Typography variant="h4">user:{user}</Typography>
        </Card>
    );
};

export default SessionInfo;