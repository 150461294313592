import React, {useEffect, useState} from 'react';
import {useQueryClient} from 'react-query';
import Canvas from "../../components/navigation/canvas/Canvas";
import {Backdrop, CircularProgress, Paper} from "@mui/material";
import {DataGridPro, GridToolbar, LicenseInfo} from '@mui/x-data-grid-pro';
import DataStreamEditDialog from './data-stream-edit-dialog/DataStreamEditDialog';
import ErrorPopup from "../../components/common/ErrorPopup";
import ScrollableDialog from "../../components/dialog/ScrollableDialog";
import TableToolbar from '../../components/common/TableToolbar';
import {columns} from "./columns";
import Hooks from './hooks';
import Helpers from './helpers';
import Handlers from './handlers'

const muiDataGridProLicenseKey = '8a27861be58ed962b9e8ac9f083548aeT1JERVI6MzI2OTEsRVhQSVJZPTE2Njg2MTk5MzUwMDAsS0VZVkVSU0lPTj0x';

const DataStreams = ({}) => {

  const [consumerId, setConsumerId] = useState(null);
  const [dataStreamFields, setDataStreamFields] = useState(['id', 'name', 'description', 'provider_id',
    'owner_id', 'provider_properties', 'created_by', 'updated_by']);
  const [dataStreamId, setDataStreamId] = useState(null);
  const [description, setDescription] = useState('');
  const [dialogContent, setDialogContent] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [editMode, setEditMode] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [errDisplayText, setErrDisplayText] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [name, setName] = useState('');
  const [originalConsumers, setOriginalConsumers] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [providerId, setProviderId] = useState(null);
  const [rowEditing, setRowEditing] = useState([]);

  const state = {consumerId, dataStreamFields, dataStreamId, description, dialogContent, dialogOpen, dialogTitle,
    editMode, editOpen, errDisplayText, errorOpen, name, originalConsumers, ownerId, providerId, rowEditing};

  const setState = {setConsumerId, setDataStreamFields, setDataStreamId, setDescription, setDialogContent,
    setDialogOpen, setDialogTitle, setEditMode, setEditOpen, setErrDisplayText, setErrorOpen, setName,
    setOriginalConsumers, setOwnerId, setProviderId, setRowEditing};

  const queryClient = useQueryClient();

  useEffect(() => {
    LicenseInfo.setLicenseKey(muiDataGridProLicenseKey);
  }, []);

  useEffect(() => Helpers.initializeColumns(columns, queryClient, setState), []);

  const getDataStreams = Hooks.useDataStreamQuery('GET', queryClient, state, setState);
  Hooks.useDataStreamConsumerQuery('GET', state, setState);

  return (
    <>
      <Canvas appName='nGEST' title='Data Streams'>
        <TableToolbar editHandler={() => Handlers.handleCreate(setState)}
                      onRefresh={() => Handlers.refreshTable(queryClient)}/>
        {
          !getDataStreams.data ?
            null :
            <Paper sx={{height: '75vh', '& .disabled': {color: "#b2b29b"}}}>
              <DataGridPro
                sx={{height: '90%'}}
                columns={columns}
                rows={getDataStreams.data}
                components={{Toolbar: GridToolbar}}
                disableSelectionOnClick
                getRowClassName={(params) => params.row.disabled ? 'disabled' : ''}
              />
            </Paper>
        }
      </Canvas>
      <DataStreamEditDialog
        state={state}
        setState={setState}
      />
      <ScrollableDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        content={dialogContent}
        title={dialogTitle}
        scroll={'paper'}
      />
      <ErrorPopup
        open={errorOpen}
        setOpen={setErrorOpen}
        displayText={errDisplayText}
      />
      <Backdrop open={getDataStreams.isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
};

export default DataStreams;