import React, {useEffect} from "react";
import {useQueryClient} from "react-query";
import {Formik} from "formik";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from "@mui/material/TextField";
import DataStreamDropDown from '../data-stream-dropdown/DataStreamDropdown';
import PageHooks from "../hooks";
import DialogHooks from "./hooks";
import PageHandlers from '../handlers';
import DialogHandlers from './handlers';

function DataStreamEditDialog({state, setState}) {

  const queryClient = useQueryClient();

  useEffect(async () => await queryClient.refetchQueries('getProperties'), [state.providerId]);

  const postDataStreamConsumer = PageHooks.useDataStreamConsumerQuery('POST', state, setState);
  const deleteDataStreamConsumer = PageHooks.useDataStreamConsumerQuery('DELETE', state, setState);
  const postDataStream = PageHooks.useDataStreamQuery('POST', queryClient, state, setState,
    {postDataStreamConsumer: postDataStreamConsumer});
  const putDataStream = PageHooks.useDataStreamQuery('PUT', queryClient, state, setState);

  const getConsumers = DialogHooks.useConsumerQuery('GET', setState);
  const getProviders = DialogHooks.useProviderQuery('GET', setState);
  const getOwners = DialogHooks.useOwnerQuery('GET', setState);
  const getProperties = DialogHooks.usePropertiesQuery(state, setState, getProviders);
  const getInitialValues = DialogHooks.useInitialValuesQuery(state, setState, getProperties);

  return (
    <Dialog
      open={state.editOpen}
      onClose={() => DialogHandlers.handleClose(setState)}
      maxWidth='lg'
      fullWidth={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {
          state.editMode === 'edit' ? `Edit Data Stream ${state.rowEditing.id}` :
            state.editMode === 'create' ? 'Create Data Stream' : ''
        }
      </DialogTitle>
      {
        !getInitialValues.data ?
          null :
          <Formik
            initialValues={getInitialValues.data}

            validate={values => DialogHandlers.validate(values, getProperties)}

            onSubmit={state.editMode === 'edit' ? (values) => PageHandlers.handleEditSubmit(values,
              queryClient, state, setState, putDataStream, postDataStreamConsumer, deleteDataStreamConsumer) :
              state.editMode === 'create' ? (values) => PageHandlers.handleCreateSubmit(values, state,
                setState, state.dataStreamFields, postDataStream) :
                () => {}}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                resetForm,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>
                <div style={{margin: '2%'}}>
                  <Grid container spacing={2}>
                    {/* ##########################[ Row 1 ]############################## */}
                    <Grid item xs={4}>
                      <TextField
                        name='name'
                        label='Name'
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        fullWidth
                        margin='normal'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={8}/>
                    {/* ##########################[ Row 2 ]############################## */}
                    <Grid item xs={6}>
                      <TextField
                        name='description'
                        label='Description'
                        value={values.description}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                        fullWidth
                        margin='normal'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Row 3 ]############################## */}
                    <Grid item xs={6}>
                      <DataStreamDropDown
                        fieldName='owner'
                        dataList={!getOwners.data ? null : getOwners.data}
                        value={state.ownerId || ''}
                        handleChange={(selection) => DialogHandlers.handleOwnerSelect(selection, setState)}
                        isMultiple={false}
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Row 4 ]############################## */}
                    <Grid item xs={6}>
                      <DataStreamDropDown
                        fieldName='provider'
                        dataList={!getProviders.data ? null : getProviders.data}
                        value={state.providerId || ''}
                        handleChange={(selection) => DialogHandlers.handleProviderSelect(selection, values, resetForm,
                          setState, getProperties)}
                        isMultiple={false}
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Row 5 thru x ]############################## */}
                    <Grid item xs={12}>
                      <List sx={{padding: 0}}>
                        {
                          !getProperties.data ?
                            null :
                            getProperties.data.map(field => (
                              <ListItem key={field.name} sx={{padding: 0}}>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <TextField
                                      name={field.name}
                                      label={field.name}
                                      value={values[field.name]}
                                      error={Boolean(touched[field.name] && errors[field.name])}
                                      helperText={touched[field.name] && errors[field.name]}
                                      fullWidth
                                      margin='normal'
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      variant='outlined'
                                    />
                                  </Grid>
                                  <Grid item xs={6}/>
                                </Grid>
                              </ListItem>
                            ))
                        }
                      </List>
                    </Grid>
                    {/* ##########################[ Row (x+1) ]############################## */}
                    <Grid item xs={6}>
                      <DataStreamDropDown
                        fieldName='consumers'
                        dataList={!getConsumers.data ? null : getConsumers.data}
                        value={state.consumerId || []}
                        handleChange={(selection) => DialogHandlers.handleConsumerSelect(selection, state, setState)}
                        isMultiple={true}
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Row (x+2) ]############################## */}
                    <Grid item xs={12}>
                      <div>
                        <Button
                          color='primary'
                          disabled={isSubmitting}
                          onClick={() => DialogHandlers.handleClose(setState)}
                          size='large'
                          type='button'
                          variant='outlined'
                        >
                          Cancel
                        </Button>
                        &nbsp;
                        <Button
                          color='primary'
                          disabled={isSubmitting || !state.ownerId || !state.providerId ||
                            !state.consumerId || (!state.consumerId.length) ||
                            Object.keys(errors).length !== 0 || !values.name}
                          size='large'
                          type='submit'
                          variant='contained'
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </form>
            )}
          </Formik>
      }
    </Dialog>
  );
}

export default DataStreamEditDialog;