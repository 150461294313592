import Helpers from './helpers';

const handleClose = (setState) => {
  setState.setName('');
  setState.setDescription('');
  setState.setEditOpen(false);
};

const handleConsumerSelect = (selection, state, setState) => {
  let consumers = JSON.parse(JSON.stringify(state.consumerId || []));

  if (consumers.includes(selection.value)) {
    consumers = consumers.filter(id => id !== selection.value);
  }
  else {
    consumers.push(selection.value);
  }

  setState.setConsumerId(consumers);
};

const handleOwnerSelect = (selection, setState) => {
  setState.setOwnerId(selection.value);
};

const handleProviderSelect = async (selection, values, resetForm, setState, getProperties) => {
  setState.setProviderId(selection.value);
  setState.setName(values.name);
  setState.setDescription(values.description);

  const newFields = !getProperties.data ? null : getProperties.data;

  resetForm({
    values: {
      name: values.name,
      description: values.description,
      provider_id: selection.value,
      ...Helpers.prettifyProperties(newFields)
    }
  });
};

const validate = async (values, getProperties) => {
  const fields = !getProperties.data ? null : getProperties.data;

  const validationObject = Helpers.generateValidationSchema(fields);

  let errors = {};

  for (let field in validationObject) {
    try {
      await validationObject[field].validate(values[field]);
    } catch (err) {
      errors[field] = err.errors[0];
    }
    // TODO will probably have to turn an array of errors from Yup into a single string with commas or something (Formik doesn't tell you how to do multiple errors)
  }

  return errors;
};

export default {handleClose, handleConsumerSelect, handleOwnerSelect, handleProviderSelect, validate};