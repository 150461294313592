import React, {useEffect, useState} from 'react';
import {useQuery, useQueryClient, useMutation} from 'react-query';
import Canvas from "../../components/navigation/canvas/Canvas";
import {Backdrop, CircularProgress, IconButton, Paper, Tooltip} from "@mui/material";
import {DataGridPro, GridToolbar, LicenseInfo} from '@mui/x-data-grid-pro';
import {columns} from '../../components/tables/ownersColumns';
import OwnersService from '../../apis/OwnersService';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TableToolbar from '../../components/common/TableToolbar';
import {mockOwners} from '../../components/tables/mockOwnersData';
import IyoAuth from '../../auth/Auth';
import OwnerEditDialog from '../../components/owners/OwnerEditDialog';
import ErrorPopup from "../../components/common/ErrorPopup";

const muiDataGridProLicenseKey = '8a27861be58ed962b9e8ac9f083548aeT1JERVI6MzI2OTEsRVhQSVJZPTE2Njg2MTk5MzUwMDAsS0VZVkVSU0lPTj0x';

const minute = 60000;

const Owners = ({}) => {

  // ####################[ Setup and Initialization ]####################
  const queryClient = useQueryClient();

  const initializeColumns = () => {
    columns.push({
      field: 'actions',
      headerName: 'Actions',
      description: 'Actions',
      minWidth: 175,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton color='primary' onClick={(event) => handleEdit(event, params.row)}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
            &nbsp;
            {
              params.row.disabled ?
                <Tooltip title="Enable">
                  <IconButton color='secondary' onClick={(event) => handleDisableSubmit(event, params.row)}>
                    <VisibilityOffIcon/>
                  </IconButton>
                </Tooltip> :
                <Tooltip title="Disable">
                  <IconButton color='primary' onClick={(event) => handleDisableSubmit(event, params.row)}>
                    <VisibilityIcon/>
                  </IconButton>
                </Tooltip>
            }
          </>
        );
      }
    });
  };

  // useEffect(clearQueryCache, []);

  useEffect(initializeColumns, []);

  useEffect(() => {
    LicenseInfo.setLicenseKey(muiDataGridProLicenseKey);
  }, []);

  // ####################[ State Definitions ]####################
  const [editOpen, setEditOpen] = useState(false);
  const [rowEditing, setRowEditing] = useState([]);
  const [errDisplayText, setErrDisplayText] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [editMode, setEditMode] = useState('');

  // ####################[ Query Definitions ]####################
  const getOwners = useQuery('getOwners', async () => {
      return await OwnersService.getAll();
      // return mockOwners;
    },
    {
      onError: (error) => {
        handleError(error);
      },
      retry: false,
      staleTime: Infinity,
      // cacheTime: (3 * minute)
    }
  );

  const postOwner = useMutation('postOwner', async (data) => {
    return await OwnersService.post(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getOwners');
    },
    onError: (error) => {
      handleError(error);
    }
  });

  const putOwner = useMutation('putOwner', async (data) => {
    return await OwnersService.put(rowEditing.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getOwners');
    },
    onError: (error) => {
      handleError(error);
    }
  });

  // ####################[ Event Handlers ]####################
  const handleCreate = async (event, row) => {
    setEditMode('create');
    setRowEditing(null);
    setEditOpen(true);
  };

  const handleCreateSubmit = async (values) => {
    console.info(`Creating new owner...`);

    let body = values;

    if (body.latitude === '') {
      body.latitude = null;
    }

    if (body.longitude === '') {
      body.longitude = null;
    }

    postOwner.mutate({...values, created_by: await IyoAuth.getUser(), updated_by: await IyoAuth.getUser()});

    setEditOpen(false);
  };

  const handleDisableSubmit = async (event, row) => {
    console.info(`${row.disabled ? 'Enabling' : 'Disabling'} user ${row.id}`);
    setRowEditing(row);

    const body = {id: row.id, disabled: row.disabled ? 0 : 1, updated_by: await IyoAuth.getUser()};

    putOwner.mutate(body);
  };

  const handleEdit = async (event, row) => {
    setEditMode('edit');
    setRowEditing(row);
    setEditOpen(true);
  };

  const handleEditSubmit = async (values) => {
    console.info(`Editing owner ${rowEditing.id}.`);

    let changed = [];
    for (let field in values) {
      if (values[field] !== rowEditing[field]) {
        changed.push([field, values[field]]);
      }
    }

    if (changed.length === 0) {
      setEditOpen(false);
      return;
    }

    changed.push(['updated_by', await IyoAuth.getUser()]);
    changed.push(['id', rowEditing.id]);
    const body = Object.fromEntries(changed);

    if (body?.latitude === '') {
      body.latitude = null;
    }

    if (body?.longitude === '') {
      body.longitude = null;
    }

    putOwner.mutate(body);

    setEditOpen(false);
  };

  const handleError = (error) => {
    setErrorOpen(false);
    setErrDisplayText(`${error.message}: ${JSON.stringify(error.response.data)}`);
    setErrorOpen(true);

    console.error(`${error.message}: ${JSON.stringify(error.response.data)}`);
  };

  const refreshTable = async () => {
    console.info('Refreshing table');
    await queryClient.invalidateQueries('getOwners');
  };

  // ####################[ The Component ]####################
  return (
    <>
      <Canvas appName='nGEST' title='Owners'>
        <TableToolbar editHandler={handleCreate} onRefresh={refreshTable}/>
        {
          !getOwners.data ?
            null :
            <Paper sx={{ height: '75vh', '& .disabled': {color: "#b2b29b"} }}>
              <DataGridPro
                sx={{ height: '90%'}}
                columns={columns}
                rows={getOwners.data}
                components={{ Toolbar: GridToolbar }}
                disableSelectionOnClick
                getRowClassName={(params) => params.row.disabled ? 'disabled' : ''}
              />
            </Paper>
        }
      </Canvas>
      <OwnerEditDialog
        type='edit'
        open={editOpen}
        setOpen={setEditOpen}
        rowEditing={rowEditing}
        mode={editMode}
        handleEditSubmit={handleEditSubmit}
        handleCreateSubmit={handleCreateSubmit}
      />
      <ErrorPopup
        open={errorOpen}
        setOpen={setErrorOpen}
        displayText={errDisplayText}
      />
      <Backdrop open={getOwners.isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
};

export default Owners;