import IyoAuth from "../../auth/Auth";
import Helpers from "./helpers";

const handleCreate = async (setState) => {
  setState.setEditMode('create');
  setState.setRowEditing(null);
  setState.setProviderId(null);
  setState.setOwnerId(null);
  setState.setConsumerId(null);
  setState.setName('');
  setState.setDescription('');

  setState.setEditOpen(true);
};

const handleCreateSubmit = async (values, state, setState, dataStreamFields, postDataStream) => {
  let body = {
    provider_properties: {}
  };

  for (const [key, value] of Object.entries(values)) {

    if (dataStreamFields.includes(key)) {
      body[key] = value;
    } else {
      body.provider_properties[key] = value;
    }
  }

  body['provider_id'] = state.providerId;
  body['owner_id'] = state.ownerId;

  postDataStream.mutate({...body, created_by: await IyoAuth.getUser(), updated_by: await IyoAuth.getUser()});

  setState.setEditOpen(false);
};

const handleDialogOpen = async (event, fieldName, row, setState) => {
  if (fieldName === 'provider_schema') {
    setState.setDialogContent(row.provider_schema);
    setState.setDialogTitle('Provider JSON Schema');
  }
  else if (fieldName === 'provider_properties') {
    setState.setDialogContent(row.provider_properties);
    setState.setDialogTitle('Provider Properties');
  }
  else if (fieldName === 'data_stream_provider_properties') {
    setState.setDialogContent(row.data_stream_provider_properties);
    setState.setDialogTitle('Data Stream Properties');
  }
  setState.setDialogOpen(true);
};

const handleDialogClose = (setState) => {
  setState.setDialogOpen(false);
};

const handleEdit = async (event, row, queryClient, setState) => {
  setState.setEditMode('edit');
  setState.setRowEditing(row);
  setState.setProviderId(row.provider_id);
  setState.setOwnerId(row.owner_id);

  setState.setDataStreamId(row.id);
  await queryClient.refetchQueries('getDataStreamConsumers');

  setState.setEditOpen(true);
};

const handleEditSubmit = async (values, queryClient, state, setState, putDataStream, postDataStreamConsumer,
                                deleteDataStreamConsumer) => {
  await Helpers.updateDataStream(values, state, putDataStream);
  await Helpers.updateDataStreamConsumers(state, postDataStreamConsumer, deleteDataStreamConsumer);

  await queryClient.invalidateQueries('getDataStreams');

  setState.setEditOpen(false);
};

const refreshTable = async (queryClient) => {
  await queryClient.invalidateQueries('getDataStreams');
  console.info('Table refreshed');
};


export default {handleCreate, handleCreateSubmit, handleDialogOpen, handleDialogClose, handleEdit, handleEditSubmit,
  refreshTable};

