import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Formik} from "formik";
import Grid from '@mui/material/Grid';
import React from "react";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";

function OwnerEditDialog(props) {

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth='lg'
      fullWidth={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {
          props.mode === 'edit' ? `Edit Owner ${props.rowEditing.id}` :
            props.mode === 'create' ? 'Create Owner' : ''
        }
      </DialogTitle>
      <Formik
        initialValues={{
          name: props.rowEditing?.name ? props.rowEditing.name : '',
          description: props.rowEditing?.description ? props.rowEditing.description : '',
          address_1: props.rowEditing?.address_1 ? props.rowEditing.address_1 : '',
          address_2: props.rowEditing?.address_2 ? props.rowEditing.address_2 : '',
          city: props.rowEditing?.city ? props.rowEditing.city : '',
          state: props.rowEditing?.state ? props.rowEditing.state : '',
          zipcode: props.rowEditing?.zipcode ? props.rowEditing.zipcode : '',
          latitude: props.rowEditing?.latitude || props.rowEditing?.latitude === 0 ? props.rowEditing.latitude : '',
          longitude: props.rowEditing?.longitude || props.rowEditing?.longitude === 0 ? props.rowEditing.longitude : ''
        }}

        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required')
        })}

        onSubmit={props.mode === 'edit' ? (values) => props.handleEditSubmit(values) :
          props.mode === 'create' ? (values) => props.handleCreateSubmit(values) :
            () => {}}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
          <form onSubmit={handleSubmit}>
            <div style={{margin: '2%'}}>
              <Grid container spacing={2}>
                {/* ##########################[ Row 1 ]############################## */}
                <Grid item xs={4}>
                  <TextField
                    name='name'
                    label='Name'
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    fullWidth
                    margin='normal'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={8}/>
                {/* ##########################[ Row 2 ]############################## */}
                <Grid item xs={6}>
                  <TextField
                    name='description'
                    label='Description'
                    value={values.description}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    fullWidth
                    margin='normal'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={6}/>
                {/* ##########################[ Row 3 ]############################## */}
                <Grid item xs={6}>
                  <TextField
                    name='address_1'
                    label='Address 1'
                    value={values.address_1}
                    error={Boolean(touched.address_1 && errors.address_1)}
                    helperText={touched.address_1 && errors.address_1}
                    fullWidth
                    margin='normal'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='address_2'
                    label='Address 2'
                    value={values.address_2}
                    error={Boolean(touched.address_2 && errors.address_2)}
                    helperText={touched.address_2 && errors.address_2}
                    fullWidth
                    margin='normal'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant='outlined'
                  />
                </Grid>
                {/* ##########################[ Row 4 ]############################## */}
                <Grid item xs={4}>
                  <TextField
                    name='city'
                    label='City'
                    value={values.city}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                    fullWidth
                    margin='normal'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='state'
                    label='State'
                    value={values.state}
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                    fullWidth
                    margin='normal'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='zipcode'
                    label='ZIP'
                    value={values.zipcode}
                    error={Boolean(touched.zipcode && errors.zipcode)}
                    helperText={touched.zipcode && errors.zipcode}
                    fullWidth
                    margin='normal'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant='outlined'
                  />
                </Grid>
                {/* ##########################[ Row 5 ]############################## */}
                <Grid item xs={6}>
                  <TextField
                    name='latitude'
                    label='Latitude'
                    value={values.latitude}
                    error={Boolean(touched.latitude && errors.latitude)}
                    helperText={touched.latitude && errors.latitude}
                    fullWidth
                    margin='normal'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='longitude'
                    label='Longitude'
                    value={values.longitude}
                    error={Boolean(touched.longitude && errors.longitude)}
                    helperText={touched.longitude && errors.longitude}
                    fullWidth
                    margin='normal'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant='outlined'
                  />
                </Grid>
                {/* ##########################[ Row 6 ]############################## */}
                <Grid item xs={12}>
                  <div>
                    <Button
                      color='primary'
                      disabled={isSubmitting}
                      onClick={handleClose}
                      size='large'
                      type='button'
                      variant='outlined'
                    >
                      Cancel
                    </Button>
                    &nbsp;
                    <Button
                      color='primary'
                      disabled={isSubmitting}
                      size='large'
                      type='submit'
                      variant='contained'
                    >
                      Save
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default OwnerEditDialog;