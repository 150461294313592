import React, {useEffect, useState} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import Canvas from "../../components/navigation/canvas/Canvas";
import {Backdrop, CircularProgress, Dialog, IconButton, Paper, Tooltip} from "@mui/material";
import {DataGridPro, GridToolbar, LicenseInfo} from '@mui/x-data-grid-pro';
import {columns} from '../../components/tables/consumerTypeColumns';
import ConsumerTypeService from '../../apis/ConsumerTypeService';
import TableToolbar from '../../components/common/TableToolbar';
import ErrorPopup from "../../components/common/ErrorPopup";
import InfoIcon from "@mui/icons-material/Info";
import ScrollableDialog from "../../components/dialog/ScrollableDialog";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const muiDataGridProLicenseKey = '8a27861be58ed962b9e8ac9f083548aeT1JERVI6MzI2OTEsRVhQSVJZPTE2Njg2MTk5MzUwMDAsS0VZVkVSU0lPTj0x';

const ConsumerTypes = ({}) => {

    // ####################[ Setup and Initialization ]####################
    const queryClient = useQueryClient();

    const initializeColumns = () => {
        columns.push({
            field: 'actions',
            headerName: 'Actions',
            description: 'Actions',
            minWidth: 175,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="View Schema">
                            <IconButton color='primary' onClick={(event) => handleDialogOpen(event, params.row)}>
                                <InfoIcon/>
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        });
    };

    useEffect(initializeColumns, []);

    useEffect(() => {
        LicenseInfo.setLicenseKey(muiDataGridProLicenseKey);
    }, []);

    // ####################[ State Definitions ]####################
    const [dialogOpen, setDialogOpen] = useState(false);
    const [schema, setSchema] = useState([]);
    const [errDisplayText, setErrDisplayText] = useState('');
    const [errorOpen, setErrorOpen] = useState(false);

    // ####################[ Query Definitions ]####################
    const getConsumerTypes = useQuery('getConsumerTypes', async () => {
            return await ConsumerTypeService.getAll();
        },
        {
            onError: (error) => {
                handleError(error);
            },
            retry: false,
            staleTime: Infinity,
            // cacheTime: (3 * minute)
        }
    );

    const handleError = (error) => {
        setErrorOpen(false);
        setErrDisplayText(`${error.message}: ${JSON.stringify(error.response.data)}`);
        setErrorOpen(true);

        console.error(`${error.message}: ${JSON.stringify(error.response.data)}`);
    };

    // ####################[ Event Handlers ]####################
    const handleDialogOpen = async (event, row) => {
        setSchema(row.schema);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const refreshTable = async () => {
        console.info('Refreshing table');
        await queryClient.invalidateQueries('getConsumerTypes');
    };

    // ####################[ The Component ]####################
    return (
        <>
            <Canvas appName='nGEST' title='Consumer Types'>
                <TableToolbar onRefresh={refreshTable}/>
                {
                    !getConsumerTypes.data ?
                        null :
                        <Paper sx={{height: '75vh', '& .disabled': {color: "#b2b29b"}}}>
                            <DataGridPro
                                sx={{height: '90%'}}
                                columns={columns}
                                rows={getConsumerTypes.data}
                                components={{Toolbar: GridToolbar}}
                                disableSelectionOnClick
                                getRowClassName={(params) => params.row.disabled ? 'disabled' : ''}
                            />
                        </Paper>
                }
            </Canvas>
            <ScrollableDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                close={handleDialogClose}
                schema={schema}
                scroll={'paper'}
            />
            <ErrorPopup
                open={errorOpen}
                setOpen={setErrorOpen}
                displayText={errDisplayText}
            />
            <Backdrop open={getConsumerTypes.isLoading}>
                <CircularProgress color="primary"/>
            </Backdrop>
        </>
    );
};

export default ConsumerTypes;