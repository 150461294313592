import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RolesService from './apis/RolesService.js';

const initialState = {
  businessId: '0',
  businessName: 'Init Business Name',
  status: 'idle',
  roles: [],
  attemptedLogin: '',
};

export const setBusinessAsync = createAsyncThunk(
    'userContext/fetchUserContext',
    async (business) => {
      const roleResponse = await RolesService.fetchAllByBusinessId(business.id);
      const roles = roleResponse.data.map(role => role.name);
      return {
        businessId: business.id,
        businessName: business.name,
        roles
      }
    }
);

export const userContextSlice = createSlice({
  name: 'userContext',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setBusiness: (state, action) => {
      return {...state,
        businessId: action.payload.id,
        businessName: action.payload.name
      };
    },
    setAttemptedLogin: (state, action) => {
      return {...state,
        attemptedLoginUser: action.payload.attemptedLoginUser,
        attemptedLoginPass: action.payload.attemptedLoginPass,
        // attemptedLoginUserObj: action.payload.attemptedLoginUserObj
      };
    }
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
        .addCase(setBusinessAsync.pending, (state) => {
          return {...state, status: 'loading'};
        })
        .addCase(setBusinessAsync.fulfilled, (state, action) => {
          const results = action.payload;
          return {...state,
            status: 'idle',
            businessId: results.businessId,
            businessName: results.businessName,
            roles: results.roles
          };
        });
  },
});

export const { setBusiness, setAttemptedLogin } = userContextSlice.actions;
export const selectBusinessId = (state) => state.userContext.businessId;
export const selectBusinessName = (state) => state.userContext.businessName;
export const selectRoles = (state) => state.userContext.roles;
export const attemptedLoginUser = (state) => state.userContext.attemptedLoginUser;
export const attemptedLoginPass = (state) => state.userContext.attemptedLoginPass;
// export const attemptedLoginUserObj = (state) => state.userContext.attemptedLoginUserObj;

export default userContextSlice.reducer;
