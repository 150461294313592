import React, {useEffect, useState} from 'react';
import {useQuery, useQueryClient, useMutation} from 'react-query';
import Canvas from "../../components/navigation/canvas/Canvas";
import {Backdrop, CircularProgress, IconButton, Paper, Tooltip} from "@mui/material";
import {DataGridPro, GridToolbar, LicenseInfo} from '@mui/x-data-grid-pro';
import {columns} from '../../components/tables/consumerColumns';
import ConsumerService from '../../apis/ConsumerService';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TableToolbar from '../../components/common/TableToolbar';
import IyoAuth from '../../auth/Auth';
import ConsumerEditDialog from '../../components/dialog/ConsumerEditDialog';
import ErrorPopup from "../../components/common/ErrorPopup";
import InfoIcon from "@mui/icons-material/Info";
import ScrollableDialog from "../../components/dialog/ScrollableDialog";

const muiDataGridProLicenseKey = '8a27861be58ed962b9e8ac9f083548aeT1JERVI6MzI2OTEsRVhQSVJZPTE2Njg2MTk5MzUwMDAsS0VZVkVSU0lPTj0x';

const minute = 60000;

const Consumers = ({}) => {

  // ####################[ Setup and Initialization ]####################
  const consumer_fields = ['id', 'name', 'description', 'consumer_type_id', 'created_by', 'updated_by'];

  const queryClient = useQueryClient();

  const initializeColumns = () => {
    columns.push({
      field: 'actions',
      headerName: 'Actions',
      description: 'Actions',
      minWidth: 175,
      renderCell: (params) => {
        return (
            <>
              <Tooltip title="Edit">
                <IconButton color='primary' onClick={(event) => handleEdit(event, params.row)}>
                  <EditIcon/>
                </IconButton>
              </Tooltip>
              &nbsp;
              {
                params.row.disabled ?
                    <Tooltip title="Enable">
                      <IconButton color='secondary' onClick={(event) => handleDisableSubmit(event, params.row)}>
                        <VisibilityOffIcon/>
                      </IconButton>
                    </Tooltip> :
                    <Tooltip title="Disable">
                      <IconButton color='primary' onClick={(event) => handleDisableSubmit(event, params.row)}>
                        <VisibilityIcon/>
                      </IconButton>
                    </Tooltip>
              }
              <Tooltip title="View Properties">
                <IconButton color='primary' onClick={(event) => handleDialogOpen(event, params.row)}>
                  <InfoIcon/>
                </IconButton>
              </Tooltip>
          </>
        );
      }
    });
  };

  // useEffect(clearQueryCache, []);

  useEffect(initializeColumns, []);

  useEffect(() => {
    LicenseInfo.setLicenseKey(muiDataGridProLicenseKey);
  }, []);

  // ####################[ State Definitions ]####################
  const [scrollableDialogOpen, setScrollableDialogOpen] = useState(false);
  const [scrollableDialogData, setScrollableDialogData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [rowEditing, setRowEditing] = useState([]);
  const [errDisplayText, setErrDisplayText] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [editMode, setEditMode] = useState('');
  const [consumerTypeId, setConsumerTypeId] = useState(null);

  // ####################[ Query Definitions ]####################
  const getConsumers = useQuery('getConsumers', async () => {
        return await ConsumerService.getAll();
        // return mockOwners;
      },
      {
        onError: (error) => {
          handleError(error);
        },
        retry: false,
        staleTime: Infinity,
        // cacheTime: (3 * minute)
      }
  );

  const postConsumer = useMutation('postConsumer', async (data) => {
    return await ConsumerService.post(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getConsumers');
    },
    onError: (error) => {
      handleError(error);
    }
  });

  const putConsumer = useMutation('putConsumer', async (data) => {
    return await ConsumerService.put(rowEditing.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getConsumers');
    },
    onError: (error) => {
      handleError(error);
    }
  });

  // ####################[ Event Handlers ]####################
  const handleDialogOpen = async (event, row) => {
    setScrollableDialogData(row.properties);
    setScrollableDialogOpen(true);
  };

  const handleDialogClose = () => {
    setScrollableDialogOpen(false);
  };

  const handleCreate = async (event, row) => {
    setEditMode('create');
    setRowEditing([]);
    setConsumerTypeId(null);
    setEditOpen(true);
  };

  const handleCreateSubmit = async (values) => {

    let body = {
      properties: {}
    };

    for (const [key, value] of Object.entries(values)) {

      if (consumer_fields.includes(key)) {
        body[key] = value;
      } else {
        body.properties[key] = value;
      }
    }

    postConsumer.mutate({...body, created_by: await IyoAuth.getUser(), updated_by: await IyoAuth.getUser()});

    setEditOpen(false);
  };

  const handleDisableSubmit = async (event, row) => {
    console.info(`${row.disabled ? 'Enabling' : 'Disabling'} consumer ${row.id}`);
    setRowEditing(row);

    const body = {id: row.id, disabled: row.disabled ? 0 : 1, updated_by: await IyoAuth.getUser()};

    putConsumer.mutate(body);
  };

  const handleEdit = async (event, row) => {
    setEditMode('edit');
    setRowEditing(row);
    setConsumerTypeId(row.consumer_type_id);
    setEditOpen(true);
  };

  const handleEditSubmit = async (values) => {

    let body = {
      properties: {}
    };

    for (let field in values) {
      if (values[field] !== rowEditing[field]) {
        if (consumer_fields.includes(field)) {
          body[field] = values[field];
        } else {
          body.properties[field] = values[field];
        }
      }
    }

    body['consumer_type_id'] = values.consumer_type_id;
    body['updated_by'] = await IyoAuth.getUser();
    body['id'] = rowEditing.id;

    putConsumer.mutate(body);

    setEditOpen(false);
  };

  const handleError = (error) => {
    setErrorOpen(false);
    setErrDisplayText(`${error.message}: ${JSON.stringify(error.response.data)}`);
    setErrorOpen(true);

    console.error(`${error.message}: ${JSON.stringify(error.response.data)}`);
  };

  const refreshTable = async () => {
    console.info('Refreshing table');
    await queryClient.invalidateQueries('getConsumers');
  };

  // ####################[ The Component ]####################
  return (
      <>
        <Canvas appName='nGEST' title='Consumers'>
          <TableToolbar editHandler={handleCreate} onRefresh={refreshTable}/>
          {
            !getConsumers.data ?
                null :
                <Paper sx={{ height: '75vh', '& .disabled': {color: "#b2b29b"} }}>
                  <DataGridPro
                      sx={{ height: '90%'}}
                      columns={columns}
                      rows={getConsumers.data}
                      components={{ Toolbar: GridToolbar }}
                      disableSelectionOnClick
                      getRowClassName={(params) => params.row.disabled ? 'disabled' : ''}
                  />
                </Paper>
          }
        </Canvas>
        <ConsumerEditDialog
            type='edit'
            open={editOpen}
            setOpen={setEditOpen}
            rowEditing={rowEditing}
            mode={editMode}
            consumerTypeId={consumerTypeId}
            setConsumerTypeId={setConsumerTypeId}
            handleEditSubmit={handleEditSubmit}
            handleCreateSubmit={handleCreateSubmit}
            handleError={handleError}
        />
        <ScrollableDialog
            open={scrollableDialogOpen}
            setOpen={setScrollableDialogOpen}
            close={handleDialogClose}
            content={scrollableDialogData}
            scroll={'paper'}
        />
        <ErrorPopup
            open={errorOpen}
            setOpen={setErrorOpen}
            displayText={errDisplayText}
        />
        <Backdrop open={getConsumers.isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
      </>
  );
};

export default Consumers;