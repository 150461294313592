// roles

// Dairy, businessTypeId=1

const hoofer = {id: 1, businessId:7, name: "Hoofer"};
const milker = {id: 2, businessId:7, name: "Milker"};

// Pigs, businessTypeId=2

const sowManager = {id: 3, businessId:2, name: "Sow Manager"};
const barnWorker = {id: 4, businessId:2, name: "Barn Worker"};

// Chickens, businessTypeId=3

const feedManager = {id: 5, businessId:3, name: "Feed Manager"};
const eggPicker   = {id: 6, businessId:3, name: "Egg Picker"};

// Pitchfork

const sharpener = {id: 7, businessId:1007, name: "Sharpener"};

const ROLES = [hoofer, milker, sowManager, barnWorker, feedManager, eggPicker, sharpener];

const fetchAll = async () => {
  return Promise.resolve({ data: ROLES });
}

const fetchAllByBusinessId = async (businessId) => {
  let roleArray = ROLES.filter(role => role.businessId === businessId)
  console.info('RoleService needs to be replaced with api implementation!');
  return Promise.resolve({ data: roleArray });
}

export default { fetchAll, fetchAllByBusinessId };