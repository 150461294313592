import {useQuery, useMutation} from 'react-query';
import DataStreamService from './DataStreamService';
import DataStreamConsumerService from './DataStreamConsumerService';
import Helpers from './helpers';

const useDataStreamQuery = (method, queryClient, state, setState, input) => {
  switch (method) {
    case 'GET':
      return useQuery('getDataStreams', async () => {
          return await DataStreamService.getAll();
        },
        {
          onError: (error) => {
            Helpers.produceError(error, setState);
          },
          staleTime: Infinity
        }
      );
    case 'POST':
      return useMutation('postDataStream', async (data) => {
        return await DataStreamService.post(data);
      }, {
        onSuccess: async (resp) => {
          setState.setDataStreamId(resp);
          await Helpers.submitConsumers(resp.id, state.consumerId, input.postDataStreamConsumer);
          await queryClient.invalidateQueries('getDataStreams');
        },
        onError: (error) => {
          Helpers.produceError(error, setState);
        }
      });
    case 'PUT':
      return useMutation('putDataStream', async (data) => {
        return await DataStreamService.put(state.rowEditing.id, data);
      }, {
        onSuccess: async () => {
          await queryClient.invalidateQueries('getDataStreams');
          await queryClient.invalidateQueries('getDataStreamConsumers');
        },
        onError: (error) => {
          Helpers.produceError(error, setState);
        }
      });
  }
};

const useDataStreamConsumerQuery = (method, state, setState) => {
  switch (method) {
    case 'GET':
      return useQuery(['getDataStreamConsumers', state.dataStreamId], async () => {
          return await DataStreamConsumerService.getById(state.dataStreamId);
        },
        {
          onSuccess: (data) => {
            setState.setConsumerId(data.map((rec) => rec.consumer_id));
            setState.setOriginalConsumers(data.map((rec) => rec.consumer_id));
          },
          onError: (error) => {
            Helpers.produceError(error, setState);
          },
          staleTime: Infinity
        });
    case 'POST':
      return useMutation('postDataStreamConsumer', async (data) => {
        return await DataStreamConsumerService.post(data);
      }, {
        onSuccess: async () => {
        },
        onError: (error) => {
          Helpers.produceError(error, setState);
        }
      });
    case 'DELETE':
      return useMutation('deleteDataStreamConsumer', async (data) => {
        return await DataStreamConsumerService.deleteById(data.dataStreamId, data.consumerId);
      }, {
        onError: (error) => {
          Helpers.produceError(error, setState);
        }
      });
  }
};

export default {useDataStreamQuery, useDataStreamConsumerQuery};