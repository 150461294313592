import React from 'react';
import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

const defaultItemSx = {
	flexFlow: 'column',
};

const defaultIconSx = {
	mx: 'auto',
};

const defaultTextSx = {
	fontSize: '.6rem',
	fontWeight: '700',
	textAlign: 'center',
};

const SidebarAction = ({actionCallback, label, icon, isActive, itemSx, iconSx, textSx}) => {

	const Icon = icon;

	const color = isActive ? 'primary' : 'secondary';

	return (
		<ListItemButton onClick={actionCallback}
										sx={{...defaultItemSx, ...itemSx}}
		>
			<ListItemIcon sx={{justifyContent: 'center'}}>
				<Icon color={color} fontSize='large' sx={{...defaultIconSx, ...iconSx}} />
			</ListItemIcon>
			<ListItemText primary={label} primaryTypographyProps={{
				color,
				sx: {...defaultTextSx, ...textSx}
			}}  />
		</ListItemButton>
	);
};

export default SidebarAction;