import React, {useEffect, useState} from 'react';
import Canvas from "../../components/navigation/canvas/Canvas";
import {useQuery, useQueryClient} from "react-query";
import {columns} from "../../components/tables/providerColumns";
import {Backdrop, CircularProgress, IconButton, Paper, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import {DataGridPro, GridToolbar, LicenseInfo} from "@mui/x-data-grid-pro";
import ProviderService from "../../apis/ProviderService";
import TableToolbar from "../../components/common/TableToolbar";
import ScrollableDialog from "../../components/dialog/ScrollableDialog";
import ErrorPopup from "../../components/common/ErrorPopup";

const muiDataGridProLicenseKey = '8a27861be58ed962b9e8ac9f083548aeT1JERVI6MzI2OTEsRVhQSVJZPTE2Njg2MTk5MzUwMDAsS0VZVkVSU0lPTj0x';

const Providers = ({}) => {

  // ####################[ Setup and Initialization ]####################
  const queryClient = useQueryClient();

  const initializeColumns = () => {
    columns.push({
      field: 'actions',
      headerName: 'Actions',
      description: 'Actions',
      minWidth: 175,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View Schema">
              <IconButton color='primary' onClick={(event) => handleDialogOpen(event, true, params.row)}>
                <InfoIcon/>
              </IconButton>
            </Tooltip>
            &nbsp;
            <Tooltip title="View Properties">
              <IconButton color='primary' onClick={(event) => handleDialogOpen(event, false, params.row)}>
                <BuildCircleIcon/>
              </IconButton>
            </Tooltip>
          </>
        );
      }
    });
  };

  useEffect(initializeColumns, []);

  useEffect(() => {
    LicenseInfo.setLicenseKey(muiDataGridProLicenseKey);
  }, []);

  // ####################[ State Definitions ]####################
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState([]);
  const [dialogTitle, setDialogTitle] = useState('');
  const [errDisplayText, setErrDisplayText] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);

  // ####################[ Query Definitions ]####################
  const getProviders = useQuery('getProviders', async () => {
      return await ProviderService.getAll();
    },
    {
      onError: (error) => {
        handleError(error);
      },
      retry: false,
      staleTime: Infinity,
      // cacheTime: (3 * minute)
    }
  );

  // ####################[ Event Handlers ]####################
  const handleDialogOpen = async (event, isSchema, row) => {
    if (isSchema) {
      setDialogContent(row.schema);
      setDialogTitle('JSON Schema');
    }
    else {
      setDialogContent(row.properties);
      setDialogTitle('Properties');
    }
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleError = (error) => {
    setErrorOpen(false);
    setErrDisplayText(`${error.message}: ${JSON.stringify(error.response.data)}`);
    setErrorOpen(true);

    console.error(`${error.message}: ${JSON.stringify(error.response.data)}`);
  };

  const refreshTable = async () => {
    console.info('Refreshing table');
    await queryClient.invalidateQueries('getConsumerTypes');
  };

  // ####################[ The Component ]####################
  return (
    <>
      <Canvas appName='nGEST' title='Providers'>
        <TableToolbar onRefresh={refreshTable}/>
        {
          !getProviders.data ?
            null :
            <Paper sx={{height: '75vh', '& .disabled': {color: "#b2b29b"}}}>
              <DataGridPro
                sx={{height: '90%'}}
                columns={columns}
                rows={getProviders.data}
                components={{Toolbar: GridToolbar}}
                disableSelectionOnClick
                getRowClassName={(params) => params.row.disabled ? 'disabled' : ''}
              />
            </Paper>
        }
      </Canvas>
      <ScrollableDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        close={handleDialogClose}
        content={dialogContent}
        title={dialogTitle}
        scroll={'paper'}
      />
      <ErrorPopup
        open={errorOpen}
        setOpen={setErrorOpen}
        displayText={errDisplayText}
      />
      <Backdrop open={getProviders.isLoading}>
        <CircularProgress color="primary"/>
      </Backdrop>
    </>
  );
};

export default Providers;