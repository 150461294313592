import React from "react";
import { render } from "react-dom";
import Amplify from 'aws-amplify';
import App from "./App.jsx";
import {QueryClient, QueryClientProvider} from "react-query";
import axios from "axios";
import IyoAuth from "./auth/Auth.jsx";
import { store } from './store.js';
import { Provider } from 'react-redux';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000
    }
  }
});

axios.interceptors.request.use(async request => {
  const token = await IyoAuth.getIdToken();
  if (!!token) request.headers.Authorization = `Bearer ${token}`;
  return request;
}, error => {
  console.error('API error', error);
});

Amplify.configure(window.amplifyConfig);

render (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
      <App />
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
