import axios from 'axios';
import {fetchConfig} from '../util/util';

const HOST_URL = fetchConfig('hostUrl')

const getOneById = async (providerId) => {
  return (await axios.get(`${HOST_URL}provider/${providerId}`)).data;
};

const getAll = async () => {
  return (await axios.get(`${HOST_URL}provider`)).data;
};

export default {getOneById, getAll};