import React from 'react';
import ConditionalRoute from "./ConditionalRoute";
import Auth from "../../auth/Auth";

/**
 * A Route which is only accessible by users while they are not logged in.  If user is logged in, they will be
 * redirected to the main page.
 *
 * @param children child component to render
 * @param render command to render components
 * @param att additional props to pass the inner Route component
 * @returns {JSX.Element} a Route which is only accessible when not logged in
 * @constructor
 */
export default function PublicOnlyRoute({children, render, ...att}) {
  return (
    <ConditionalRoute conditionCallback={async () => !(await Auth.isLoggedIn())}
                      render={render || (_ => children)}
                      failRedirect='/home'
                      loadMessage='Checking for Session...'
                      props={att}
    />
  );
};
