import axios from 'axios';
import {fetchConfig} from '../../util/util';

const HOST_URL = fetchConfig('hostUrl')

const getById = async (dataStreamId) => {
  return (await axios.get(`${HOST_URL}data-stream-consumer?data_stream_id=${dataStreamId}`)).data;
};

const post = async (data) => {
  return (await axios.post(`${HOST_URL}data-stream-consumer`, data)).data;
};

const deleteById = async (dataStreamId, consumerId) => {
  if (!consumerId && dataStreamId) {
    return (await axios.delete(`${HOST_URL}data-stream-consumer?data_stream_id=${dataStreamId}`)).data;
  }
  else if (!dataStreamId && consumerId) {
    return (await axios.delete(`${HOST_URL}data-stream-consumer?consumer_id=${consumerId}`)).data;
  }
  else if (dataStreamId && consumerId) {
    return (await axios.delete(`${HOST_URL}data-stream-consumer?data_stream_id=${dataStreamId}&consumer_id=${consumerId}`)).data;
  }
};

export default {getById, post, deleteById};